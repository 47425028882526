import { Link } from 'react-router-dom';
import { FooterContact } from './FooterContact';
import { FooterSocial } from './FooterSocial';
import { HTMLProps, useContext } from 'react';
import { ConsentFormContext } from '../Consent/ConsentFormContext';

export const Footer = ({ className }: HTMLProps<HTMLDivElement>) => {
  const { setShowConsentForm } = useContext(ConsentFormContext)!;

  return (
    <footer className="px-2 lg:px-24 mt-12 py-12 bg-[#00052b] text-white font-extralight flex flex-col overflow-hidden items-center">
      <div
        className={
          'flex flex-wrap flex-col items-center sm:items-start sm:flex-row gap-6 sm:gap-4 lg:gap-6 justify-between container ' +
          className
        }
      >
        {/* Left part */}
        <div className="flex xl:flex-1 flex-col justify-start items-start gap-7 text-sm sm:text-base ">
          <FooterContact />
        </div>
        {/* Middle part */}
        <div className="flex xl:flex-1 flex-col justify-between md:justify-start items-start lg:text-lg w-fit whitespace-nowrap">
          <h4 className="lg:text-lg font-semibold leading-none tracking-[0.01em] uppercase">
            Sitemap
          </h4>
          <div className="grid grid-flow-row gap-x-2 lg:gap-x-4 xl:gap-x-8 grid-cols-2 py-2">
            <Link to="/" className='hover:text-slate-500'>Home</Link>
            <Link to="/docs/model-requirements" className='hover:text-slate-500'>Documentation</Link>
            <Link to="https://blog.wearitar.com" className='hover:text-slate-500'>Blog</Link>
            <Link to="/contact-us" className='hover:text-slate-500'>Contact us</Link>
            <Link to="/about" className='hover:text-slate-500'>About us</Link>
            <Link to="/privacy" className='hover:text-slate-500'>Privacy Policy</Link>
            <Link to="/terms" className='hover:text-slate-500'>Terms of Service</Link>
            <button
              onClick={() => setShowConsentForm(true)}
              className="text-white bg-transparent border-none p-0 m-0 cursor-pointer hover:text-slate-500 focus:outline-none"
            >
              Manage Cookies
            </button>
          </div>
        </div>
        {/* Right part */}
        <div className="flex xl:flex-1 justify-center sm:justify-end items-center sm:items-start w-full sm:w-auto">
          <FooterSocial />
        </div>
        <h6 className="whitespace-nowrap w-full mt-6 md:-mt-8 text-center sm:text-start">
          &copy; 2022-{new Date().getFullYear()} Wearitar Corp. All rights
          reserved.
        </h6>
      </div>
    </footer>
  );
};
